<template>
  <section id="patient-preparation-container">
    <TabHeaderInfo
      title="Preparação do paciente"
      tooltip="Cadastre as etapas pelas quais o paciente passa
      no centro cirúrgico e tenha controle pelo dashboard
      cirúrgico"
    />

    <section class="draggable">
      <div v-if="!items.length">
        <NoFilterResults
          class="no-filter-results"
          message="Clique em adicionar e adicione as etapas do fluxo de centro cirúrgico"
        />
        <hr />
      </div>

      <draggable
        class="list-group"
        tag="ul"
        v-model="items"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        @sort="onSort"
        :disabled="!enabled"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <PatientPreparationItem
            v-for="(element,index) in items" 
            :key="index+1"  
            :item="element"
            :deletePatientPreparationTemplate="deletePatientPreparationTemplate"
            @updateEnabled="updateEnabled"
            @fixedItem="fixedItem"
            @closeEdit="closeEdit"
            @deleteItem="deletePatientPreparationTemplate"
            @updateName="value => (name = value)"
          />
        </transition-group>
        <div slot="footer" class="d-flex justify-content-center">
          <b-button
            v-if="editIndex !== null"
            variant="outline-primary"
            class="add-button mt-4"
            @click="saveItem"
            :disabled="disableSave"
          >
            Salvar
          </b-button>
          <b-button
            v-else
            class="add-button mt-4"
            variant="outline-primary"
            @click="addItem"
            size="lg"
            :disabled="disableSave"
          >
            Adicionar
          </b-button>
        </div>
      </draggable>
    </section>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'PatientPreparation',
  components: {
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo'),
    PatientPreparationItem: () => import('@/components/SurgeryCenter/Configuration/PatientPreparationItem'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    draggable
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    disableSave() {
      return !this.name && !this.enabled
    }
  },
  data: () => ({
    clinic: getCurrentClinic(),
    editIndex: null,
    item: null,
    enabled: true,
    name: null,
    drag: false,
    items: []
  }),
  async created() {
    await this.getPatientPreparationTemplates()
  },
  methods: {
    async getPatientPreparationTemplates() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getPatientPreparationTemplates(
          this.clinic.id
        )
        this.items = data.map(item => ({
          id: item.id,
          name: item.name,
          order: item.order,
          edit: false
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    addItem() {
      this.items.push({
        name: null,
        order: this.items.length + 1,
        edit: true
      })
      this.editIndex = this.items.length - 1
    },
    closeEdit(item) {
      this.editIndex = null
      this.enabled = true

      const index = this.items.findIndex(element => element.id === item.id)
      this.$set(this.items, index, {
        ...item,
        edit: false
      })
      if (!item.name) {
        this.items.splice(index, 1)
      }
    },
    fixedItem(item) {
      this.item = {
        ...item,
        edit: true
      }
      const index = this.items.indexOf(item)
      this.$set(this.items, index, this.item)
      this.editIndex = index
      this.enabled = !this.enabled
    },
    updateArrayItem(data) {
      this.$set(this.items, this.editIndex, {
        ...data,
        edit: false
      })
      this.enabled = true
      this.editIndex = null
    },
    async saveItem() {
      if (!this.item?.id) {
        await this.createPatientPreparationTemplate()
        return
      }
      await this.updatePatientPreparationTemplate()
    },
    async createPatientPreparationTemplate() {
      const isLoading = this.$loading.show()
      try {
        const item = this.items[this.editIndex]
        const { data } = await this.api.createPatientPreparationTemplate({
          clinic_id: this.clinic.id,
          name: this.name,
          order: item.order
        })
        this.updateArrayItem(data)
        this.$toast.success('Etapa cadastrada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updatePatientPreparationTemplate() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.updatePatientPreparationTemplate(
          this.item.id,
          {
            name: this.name
          }
        )
        this.updateArrayItem(data)
        this.$toast.success('Etapa atualizada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async bulkUpdatePatientPreparationTemplate(items) {
      this.enabled = false
      try {
        await this.api.bulkUpdatePatientPreparationTemplate(items)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.enabled = true
      }
    },
    async deletePatientPreparationTemplate(id) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deletePatientPreparationTemplate(id)
        this.deleteItemToArray(id)
        this.$toast.success('Preparação removida com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    deleteItemToArray(id) {
      const index = this.items.findIndex(item => item.id === id)
      this.items.splice(index, 1)
    },
    async onSort() {
      const items = this.items.map(item => ({
        id: item.id
      }))
      await this.bulkUpdatePatientPreparationTemplate(items)
    },
    updateEnabled(value) {
      this.enabled = value
    }
  }
}
</script>

<style lang="scss">
#patient-preparation-container {
  .draggable {
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 20px;

    .flip-list-move {
      transition: transform 0.5s;
    }
    .no-move {
      transition: transform 0s;
    }
    .ghost {
      opacity: 0.5;
      background: var(--neutral-100);
    }
    .list-group {
      min-height: 20px;
    }
    .list-group-item {
      cursor: move;
    }

    .add-button {
      width: 15%;
      font-weight: 600;
      font-size: min(2.6vw, 18px);
    }
  }
}
</style>
